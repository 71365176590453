<template>
  <div class="error">
    <screen-error :route="{ name: 'Home' }" :label="$t('error.button.back')" />
  </div>
</template>

<script>
import ScreenError from '@/components/Screen/Error.vue'

export default {
  name: 'Error',
  components: {
    ScreenError,
  },
}
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
