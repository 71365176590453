<template>
  <div
    class="screen-container"
    :class="[
      `screen-container--${direction}`,
      { 'screen-container--full-height': fullHeight, 'screen-container--no-header': noHeader },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ScreenContainer',
  props: {
    direction: {
      type: String,
      required: false,
      default: 'row',
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    noHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-container {
  &--row {
    @include container;

    padding: 0;
  }

  &--column {
    @include container(column);
  }

  @media (min-width: $screen-sm) {
    padding-bottom: $gutter-tablet;
  }

  @media (min-width: $screen-xl) {
    padding-bottom: $gutter-desktop;
  }

  &--full-height {
    @media (min-width: $screen-md) {
      margin-top: (-$gutter-tablet);
      padding-top: $gutter-tablet;
      height: 100%;
      overflow-y: hidden;
    }
  }

  &--no-header {
    padding-top: $gutter-mobile;
    height: 100%;

    @media (min-width: $screen-sm) {
      padding-top: $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding-top: $gutter-desktop;
    }
  }
}
</style>
